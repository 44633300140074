<template>
  <div>
       <!-- 创建 -->
        <div style="width:96%px;margin:0 auto;background:#fff;">
            <div style="text-align:left;">

               <el-row>
                    <p style="font-size:16px;line-height:40px;height:40px;">属性名：</p>
                    <el-input
                    style="width:200px;"
                    placeholder="请输入内容"
                    v-model="saveData.name"
                    clearable>
                    </el-input>
               </el-row>

                <el-row>
                    <p style="width:100%;font-size:16px;line-height:40px;height:40px;margin-top:20px;">是否启用</p>
                    <div>
                        <el-radio-group v-model="saveData.enable_status">
                        <el-radio-button :label="true">启用</el-radio-button>
                        <el-radio-button :label="false">禁用</el-radio-button>
                        </el-radio-group>
                    </div>
                </el-row>
               
                <el-row>
                    <p style="width:100%;font-size:16px;line-height:40px;height:40px;margin-top:20px;">属性值列表</p>
                    <div>
                        <table class="mytable" style="width:100%;">
                            <thead class="thead">
                                <tr class="rows ">
                                    <td class="cols" >
                                        <i class="el-icon-s-tools" style="font-size:16px;"></i>
                                    </td>
                                    <td class="cols">序号</td>
                                    <td class="cols">属性值</td>
                                    <td class="cols">加价（元）</td>
                                    <td class="cols">排序</td>
                                </tr>
                            </thead>
                            <tbody class="tbody" v-if="dispose.length>0">
                                <tr class="rows " v-for="(i,k) in dispose" :key="k">
                                    <td class="cols">
                                        <i class="el-icon-circle-plus" 
                                        @click="addLine"
                                        style="color:red;font-size:18px;cursor: pointer;"></i>
                                        <i class="el-icon-remove-outline" 
                                        @click="removeLine(k)"
                                        style="color:green;font-size:18px;margin-left:10px;cursor: pointer;"></i>
                                    </td>
                                    <td class="cols">{{i.sort}}</td>
                                    <td class="cols"><el-input size="mini" v-model="i.value"></el-input></td>
                                    <td class="cols"><el-input size="mini" v-model="i.price_copy"></el-input></td>
                                    <td class="cols">
                                        <i class="el-icon-top" style="font-size:18px;cursor: pointer;"></i>
                                        <i class="el-icon-bottom" style="font-size:18px;margin-left:10px;cursor: pointer;"></i>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="tbody" v-else>
                                <tr class="rows " >
                                    <td class="cols" colspan="7" style="text-align:center;">
                                        暂无数据 <i class="el-icon-circle-plus" @click="addLine" style="color:red;font-size:18px;cursor:pointer;"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </el-row>

            </div>

            <div slot="footer" class="dialog-footer" style="text-align:center;height:60px;line-height:60px;margin-top:60px;">
                <el-button type="primary" @click="save();">保 存</el-button>
                <el-button type="primary" @click="exit();">关 闭</el-button>
            </div>
        </div> 
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import tools from "../../../extends/tools";
import apis from "../../common/js/api2";

export default {
    props:{
        id:{
            type:Number,
            default:0
        }
    },
    data(){
        return {
            dispose:[],
            saveData:{
                enable_status:true,
                name:'',
                dispose:''
            },
            saveComplete:false,
            format:'YYYY-MM-DD HH:mm:ss'
        }
    },
    methods:{
        copy(code,item){
            
        },
        addLine(){
            let obj = {
                    value: "",
                    price: '0.00',
                    price_copy: '0.00',
                    sort: 0
                };
            this.dispose.push(obj);
        },
        removeLine(k){
            this.dispose.splice(k,1);
        },
        beforeUpload(){
            this.uploadLoading = true;
        },
        //插入图片
        pickPicture(file) {
            this.uploadLoading = false;
            this.saveData.portrait = file.font;
            this.imageUrl = this.baseUrl+file.font;
        },
        save(){
            //判断是修改还是新增
            this.dispose.forEach((item,key)=>{
                item.price = item.price_copy*100;
                item.sort = key+1;
            });

            this.saveData.dispose = JSON.stringify(this.dispose);
            
            let loader = tools.loading(false,this);
            if(this.id > 0){
                this.saveData.id = this.id;
                //保存  
                apis.goodsAttrUpdate(this.saveData).then((res)=>{
                    //通知列表页更新数据
                    tools.msg(res,this,1) && this.fleshList();
                tools.loading(loader,this);
                }).catch((err)=>{
                    tools.err(err,this);
                tools.loading(loader,this);
                });                
                return;
            }

            //新增
            apis.addGoodsAttr(this.saveData).then((res)=>{
                //通知列表页更新数据
                tools.msg(res,this,1) && this.fleshList();
                tools.loading(loader,this);
            }).catch((err)=>{
                tools.err(err,this);
                tools.loading(loader,this);
            });
        },
        exit(){
            this.$emit('shareExit')
        },
        fleshList(){
            this.$emit('fleshList')
        }
    },
    created(){
        //判断是修改还是新增
        if(this.id > 0){
            //初始化修改数据
            apis.goodsAttrDetail({id:this.id}).then((res)=>{
                this.saveData.name = res.data.name;
                this.saveData.enable_status = res.data.enable_status;
                res.data.dispose_info.forEach(element => {
                    let item = {
                        sort:element.sort,
                        price:'',
                        price_copy:tools.setPrice(element.price),
                        value:element.value
                    };
                    this.dispose.push(item);
                });

            }).catch((err)=>{
                tools.err(err,this);
            });
        }
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.cols{
    min-height:30px;line-height: 30px;white-space:nowrap;padding:0 10px 0 10px;
}
</style>